div.dashboard-header {
  background: rgba(216, 216, 225, 0.3);
  padding: 15px 30px;

  div.dropdown-item {
    position: relative;

    > img {
      cursor: pointer;
    }
  }

  .mr-15 {
    margin-right: 15px;
  }
}
