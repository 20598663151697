section.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  button.login-hook-btn {
    cursor: pointer;
    display: flex;
    font-size: 1.3em;
    box-sizing: content-box;
    margin: 20px auto 0;
    width: 70%;
    max-width: 800px;
    padding: 15px 20px;
    border-radius: 24px;
    border-color: transparent;
    background-color: rgba(66, 133, 244, 0.2);;
    box-shadow: 0 16px 60px rgba(78,79,114,.08);
    position: relative;
    align-items: center;
    color: #4285f4;
    font-weight: 500;
    &:hover {
      background-color: rgba(66, 133, 244, 0.3);
    }
    > span {
      width: 100%;
    }
  }
}
