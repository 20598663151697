@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.align-items-center {
  align-items: center;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-1 {
  flex: 1;
}
