div.extension-settings {
  width: 100%;
  padding: 20px;

  h5 {
    color: #262636;
    font-size: 24px;
    font-weight: normal;
    margin: 0;
  }

  div.settings-block {
    padding-top: 20px;
    max-width: 70%;

    span.title {
      color: #262636;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .field-title {
      color: #262636;
      font-weight: 500;
      font-size: 20px;
      line-height: 1;
      padding-left: 10px;
    }

    .field-desc {
      padding-top: 15px;
      padding-left: 10px;
      font-size: 15px;
      color: #a9a9a9;
    }

    .pro {
      background: #3C37BE;
      border-radius: 30px;
      color: white;
      font-size: 10px;
      padding: 3px 10px;
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .submit-btn {
    color: white;
    background: #3C37BE;
    border: 3.17935px solid #3C37BE;
    border-radius: 47.95px;
    padding: 10px 24px;
    cursor: pointer;
  }
}
