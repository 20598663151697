div.dashboard-side-menu {
  background: #3C37BE;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 55px);

  &.expanded {
    min-width: 200px;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;

    .category-item a {
      padding: 10px 10px 10px 27px;
    }
  }

  &.closed {
    width: 35px;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;

    .category-item a {
      padding: 10px 0;
    }
  }

  div.menu-categories {
    padding-top: 30px;
  }

  .category {
    &-name {
      color: #6D69CF;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 0 0 15px 27px;
    }
    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;

      a {
        color: white;
        line-height: 1;
        text-decoration: none;
        width: 100%;
      }

      span {
        padding-left: 5px;
      }
    }

    &-item .active {
      background: rgba(184, 181, 255, 0.3);
    }
  }

  .expand-block {
    background: rgba(184, 181, 255, 0.3);
    display: flex;
    align-items: center;
    color: white;
    padding: 5px 0;
    &.justify-content-end {
      > span {
        padding-right: 10px;
      }
    }
    > span {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
