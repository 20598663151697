.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 300px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li span {
    text-decoration: none;
    color: #333333;
    cursor: pointer;
    padding: 15px 20px;
    display: block;
  }
}
