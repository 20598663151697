.spinner {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  background-color: white;
  z-index: 9;
  justify-content: center;
}
