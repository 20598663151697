@import url(https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap);
.spinner{height:100%;width:100%;left:0;top:0;overflow:hidden;position:fixed;background-color:white;z-index:9;justify-content:center}

section.login{width:100%;height:100vh;display:flex;align-items:center;justify-content:center}section.login button.login-hook-btn{cursor:pointer;display:flex;font-size:1.3em;box-sizing:content-box;margin:20px auto 0;width:70%;max-width:800px;padding:15px 20px;border-radius:24px;border-color:transparent;background-color:rgba(66,133,244,0.2);box-shadow:0 16px 60px rgba(78,79,114,0.08);position:relative;align-items:center;color:#4285f4;font-weight:500}section.login button.login-hook-btn:hover{background-color:rgba(66,133,244,0.3)}section.login button.login-hook-btn>span{width:100%}

div.dashboard-header{background:rgba(216,216,225,0.3);padding:15px 30px}div.dashboard-header div.dropdown-item{position:relative}div.dashboard-header div.dropdown-item>img{cursor:pointer}div.dashboard-header .mr-15{margin-right:15px}

.menu{background:#ffffff;border-radius:8px;position:absolute;top:30px;right:0;width:300px;box-shadow:0 2px 2px rgba(0,0,0,0.1);opacity:0;visibility:hidden;transform:translateY(-20px);transition:opacity 0.4s ease, transform 0.4s ease, visibility 0.4s}.menu.active{opacity:1;visibility:visible;transform:translateY(0)}.menu ul{list-style:none;padding:0;margin:0}.menu li span{text-decoration:none;color:#333333;cursor:pointer;padding:15px 20px;display:block}

.menu{background:#ffffff;border-radius:8px;position:absolute;top:30px;right:0;width:300px;box-shadow:0 2px 2px rgba(0,0,0,0.1);opacity:0;visibility:hidden;transform:translateY(-20px);transition:opacity 0.4s ease, transform 0.4s ease, visibility 0.4s}.menu.active{opacity:1;visibility:visible;transform:translateY(0)}.menu ul{list-style:none;padding:0;margin:0}.menu li span{text-decoration:none;color:#333333;cursor:pointer;padding:15px 20px;display:block}

div.dashboard-side-menu{background:#3C37BE;flex-direction:column;justify-content:space-between;height:calc(100vh - 55px)}div.dashboard-side-menu.expanded{min-width:200px;transition:width .3s ease-in-out}div.dashboard-side-menu.expanded .category-item a{padding:10px 10px 10px 27px}div.dashboard-side-menu.closed{width:35px;transition:width .3s ease-in-out}div.dashboard-side-menu.closed .category-item a{padding:10px 0}div.dashboard-side-menu div.menu-categories{padding-top:30px}div.dashboard-side-menu .category-name{color:#6D69CF;font-size:12px;letter-spacing:1px;text-transform:uppercase;padding:0 0 15px 27px}div.dashboard-side-menu .category-item{cursor:pointer;display:flex;align-items:center}div.dashboard-side-menu .category-item a{color:white;line-height:1;text-decoration:none;width:100%}div.dashboard-side-menu .category-item span{padding-left:5px}div.dashboard-side-menu .category-item .active{background:rgba(184,181,255,0.3)}div.dashboard-side-menu .expand-block{background:rgba(184,181,255,0.3);display:flex;align-items:center;color:white;padding:5px 0}div.dashboard-side-menu .expand-block.justify-content-end>span{padding-right:10px}div.dashboard-side-menu .expand-block>span{cursor:pointer;font-weight:bold}

div.extension-settings{width:100%;padding:20px}div.extension-settings h5{color:#262636;font-size:24px;font-weight:normal;margin:0}div.extension-settings div.settings-block{padding-top:20px;max-width:70%}div.extension-settings div.settings-block span.title{color:#262636;font-size:22px;font-weight:bold;margin-bottom:15px}div.extension-settings div.settings-block .field-title{color:#262636;font-weight:500;font-size:20px;line-height:1;padding-left:10px}div.extension-settings div.settings-block .field-desc{padding-top:15px;padding-left:10px;font-size:15px;color:#a9a9a9}div.extension-settings div.settings-block .pro{background:#3C37BE;border-radius:30px;color:white;font-size:10px;padding:3px 10px}div.extension-settings .mt-30{margin-top:30px}div.extension-settings .mt-50{margin-top:50px}div.extension-settings .submit-btn{color:white;background:#3C37BE;border:3.17935px solid #3C37BE;border-radius:47.95px;padding:10px 24px;cursor:pointer}

*{font-family:'Poppins', sans-serif}body{background-color:white;margin:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.align-items-center{align-items:center}.d-flex{display:flex}.justify-content-between{justify-content:space-between}.justify-content-center{justify-content:center}.justify-content-end{justify-content:flex-end}.flex-column{flex-direction:column}.flex-row{flex-direction:row}.flex-1{flex:1 1}

